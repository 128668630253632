import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "components/layout";
import SEO from "components/seo";
import Contact from "components/contact";



export default function Blog() {
  const data = useStaticQuery(graphql`
    query BlogQuery {
      markdownRemark(frontmatter: {section: {eq: "blog-main"}}) {
        frontmatter {
          title
          metadescription
          text
        }
      }
      allMarkdownRemark(
        filter: {frontmatter: {section: {eq: "blog"}}}
        sort: {order: DESC, fields: [frontmatter___date]}
        limit: 1000
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              excerpt
              title
              featureImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    height: 280
                    placeholder: BLURRED
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

    return (
            <Layout>
                <SEO
                    pageData={{
                        title: data.markdownRemark.frontmatter.title,
                        description: data.markdownRemark.frontmatter.metadescription
                    }}
                />

                <div className="bg-green-50 py-16 border-b border-green-100">
                    <div className="max-w-screen-md mx-auto px-4">
                        <div className="mb-12 text-3xl md:text-5xl font-black text-center">Spending Planner blog</div>
                        <div className="" dangerouslySetInnerHTML={{ __html: data.markdownRemark.frontmatter.text }} />
                    </div>
                </div>

                <div className="bg-gray-50 py-16">
                    <div className="mx-auto px-4 max-w-screen-xl grid md:grid-cols-3 justify-between gap-x-5 gap-y-10">
                    {data.allMarkdownRemark.edges.map((edge,j) => {
                        return (
                            <Link to={edge.node.fields.slug} className="flex flex-col rounded-lg shadow bg-white pb-4" key={j}>
                                <GatsbyImage
                                    image={edge.node.frontmatter.featureImage.childImageSharp.gatsbyImageData}
                                    alt=""
                                    className="rounded-t-lg mb-6" />
                                <div className="px-3 text-xs">{edge.node.frontmatter.date}</div>
                                <div dangerouslySetInnerHTML={{ __html: edge.node.frontmatter.title }} className="px-3 font-semibold leading-tight text-2xl" />
                                <div dangerouslySetInnerHTML={{ __html: edge.node.frontmatter.excerpt }} className="px-3 block flex-1 mb-4" />
                                <div className="px-3 text-right font-semibold text-green-800 uppercase tracking-tight">Read more</div>
                            </Link>
                        );
                    })}
                    </div>
                </div>

                <Contact />

            </Layout>
        )
      }

